import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'c:navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  langColor:any;
  selectedItem: string;
  selectedFlag: string;
  paramsId: any;
  catchScroll:any;

  mapLangs:any={
    'km_KH' : {
      name: 'ខ្មែរ',
      image_path: "assets/img/flags/kh.png",
      iso_code: 'km'
    },
    'en_US' : {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      iso_code: 'en'
    },
    "zh_CN" : {
      name: "中文",
      image_path: "assets/img/flags/ch.png",
      iso_code: 'zh'
    },
    'km' : {
      lang: 'Khmer',
      image_path: "assets/img/flags/kh.png",
      lang_code: "km_KH"
    },
    'en' : {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      lang_code: "en_US"
    },
    "zh" : {
      name: "中文",
      image_path: "assets/img/flags/ch.png",
      lang_code: "zh_CN"
    },
  };

  thisFrm:any={
    activeLang: 'en_US',
    activePage: "home"
  };
  param = { value: '@ngrx translation' };
  translate: TranslateService;
  constructor(private activatedRoute: ActivatedRoute, private router:Router,translateService: TranslateService
    )
     {
      this.translate = translateService;
      this.translate.setDefaultLang('en');
     }

    ChangeLang(language: string) {
      this.translate.use(language);
      }
  ngOnInit() {


    this.activatedRoute.paramMap.subscribe(params => {
      let activeLang: string = params.get('activeLang');

      this.ChangeLang(activeLang);


      if(activeLang){
        this.thisFrm.activeLang = this.mapLangs[activeLang].lang_code;

      }
      // console.log(">>>>>activeLang", params);
      let url:Observable<string> = this.activatedRoute.url.pipe(map(segments => segments.join('')));
      // console.log(">>>>>url", url);
    });

    this.activatedRoute.url.subscribe(segments => {
      console.log(">>>>>segment", segments);

      this.thisFrm.activePage = segments[1].path; //index 1 because index 0 is the langcode and 1 is our path
    });


    // this.activatedRoute.paramMap.subscribe(param => {
    //   this.paramsId = param.get("activeLang");
    //   console.log('Get Param', this.paramsId);

      // if(this.paramsId == "en"){
      //   this.selectedItem = "English";
      //   console.log(this.selectedItem);
      // }
      // else if(this.paramsId == "kh"){
      //   this.selectedItem = "Khmer";
      //   console.log(this.selectedItem);
      // }
      // else if(this.paramsId == "zh"){
      //   this.selectedItem = "China";
      //   console.log(this.selectedItem);
      // }
    // });

    this.selectedItem = "English";
    this.selectedFlag = "../assets/img/flags/uk.png";
  }

  onChangeLang(lang_code: string, selectedFlag: string) {
    this.thisFrm.activeLang = lang_code;
    this.selectedFlag = selectedFlag;
    this.router.navigate([this.mapLangs[lang_code].iso_code, this.thisFrm.activePage]);
  }

  changeRoute(lang_code:string, pageName: string){
    this.router.navigate([this.mapLangs[lang_code].iso_code, pageName]);
  }


}
