import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecordsTypeService {

  constructor() { }

  static routers =
    {
      "post": {
        "detail": {
          "news": 'news',
          'events': 'events'
        }
      }
    };
}
