import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { HttpGetJsonService } from '../../../shared/http-service/http-get-json.service';
import { DOCUMENT } from '@angular/platform-browser';
import * as $ from 'jquery'
import AOS from 'aos';
import { ActivatedRoute } from "@angular/router";
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import { RestApiService } from 'src/app/shared/services/rest-api.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RecordsTypeService } from 'src/app/shared/services/records-type.service';

@Component({
	selector: 'app-news-pages',
	templateUrl: './news-pages.component.html',
	styleUrls: ['./news-pages.component.scss']
})
export class NewsPagesComponent implements OnInit {
	activeArticle: any = {};
	activeLang: any = 'en_US';
	lstNews: any = [];
	newIndex: any = "0";
	thisPage: any = {}
	lstSections: any = [];
	loadingNews: boolean = true;
	lstCategories: any = [];
	mapArticle: any = {};
	selectedArticleId: string;
	mapSlug: any = {};
	activeSlug: string;
	routeData: any = {};
	articlePath: string;
	isocode: string = 'en';


	lstImages = []

	lstCareers: any = [];
	loadingCareer: boolean = true;
	constructor(
		private activeRoute: ActivatedRoute,
		private $listUtil: ListUtilsService,
		private $restApi: RestApiService,
		private _renderer2: Renderer2,
		@Inject(DOCUMENT) private _document) { }

	ngOnInit() {
		// Animation
		AOS.init({});

		// Language change
		this.activeRoute.paramMap.subscribe(params => {
			this.isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[this.isocode].lang_code;

			console.log(">>>>>active lang", this.activeLang);
			if(params.has('slug')){
				this.activeSlug = params.get('slug');
				this.activeArticle = this.mapArticle[this.activeSlug];
			}

			if (this.activeArticle && this.activeArticle.main_photo_preview) {
				this.lstImages = [this.activeArticle.main_photo_preview];
			}

			// console.log('ActiveArticle',this.activeArticle);
		});
		this.activeRoute.data.subscribe(data => {
			this.routeData = data;
			// console.log('my record type',this.routeData);
			this.articlePath = RecordsTypeService.routers.post.detail[data.record_type];
		})


		// get JSON data
		// this._HttpGetJsonService.getNewsData().subscribe(data => {
		//   this.lstNews = data['data'][0].all_sections;
		// });
		this.loadNews();
		// this.slideLoad();
	}


	//Load Data from database
	loadNews() {
		this.loadingNews = true;
		this.$restApi.getByPath('/public-categories', { record_type: 'news' }).subscribe(res => {
			this.lstCategories = res.data

			let filterArticle = {
				record_type: this.routeData.record_type,
				order_col: 'posted_at',
				order_by: 'desc'
			};
			this.$restApi.getByPath('/public-articles', filterArticle).subscribe(res => {
				this.lstNews = res.data;

				console.log(">>>>>>lstNews", this.lstNews);

				if (this.lstNews && this.lstNews.length) {
					this.lstNews.forEach(article => { 
						this.mapArticle[article.slug] = article;
					});
					let slug = this.activeSlug ? this.activeSlug : this.lstNews[0].slug;
					this.activeArticle = this.mapArticle[slug] ? this.mapArticle[slug] : this.lstNews[0];
					if (this.activeArticle && this.activeArticle.main_photo_preview) {
						this.lstImages = [this.activeArticle.main_photo_preview];
					}
					console.log(">>>>>>>this.lstImages", this.lstImages);
				}
				// console.log('Article: ',this.activeArticle);
				this.loadingNews = false;
			});
		})
	}



	changeArticle(articleSlug) {
		this.activeArticle = this.mapArticle[articleSlug];
	}

	slideLoad() {
		// Slide card
		let script = this._renderer2.createElement('script');
		script.type = `text/javascript`;
		script.text = `
        {
          $(document).ready(function () {
            setTimeout(function(){
                 $('#lightSliderNews').lightSlider({
                    adaptiveHeight: true,
                    gallery:true,
                    item:1,
                    thumbItem:5,
                    slideMargin:20,
                    controls:true,
                    keyPress: true,
                  });
            },0);
        });
        }
    `;
		this._renderer2.appendChild(this._document.body, script);
	}
}
