import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  // tslint:disable: variable-name
  private _url = 'assets/json/home-page.json';
  constructor(private httpService: HttpClient) { }

  getHomeData() {
    return this.httpService.get(this._url);
  }
}
