import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isNull } from 'util';
import { Observable } from 'rxjs';

// const ENDPOINT = 'http://127.0.0.1:8000/api';
const ENDPOINT = 'http://vipayapi.redfox-ws.com/api';

@Injectable({
  providedIn: 'root'
})
export abstract class RestApiService {

  abstract URL_PATH:string = "";

  constructor(private $http:HttpClient) { }


  /**
   * HTTP request GET
   * @param url_path string url path for request
   * @param filter json object with params to filter
   * @param isAuth default true, if the request need to set auth
   */
  get(filter:Object): Observable<any> {
    return this.getByPath(this.URL_PATH, filter);
  }

  getByPath(path:string, filter:Object): Observable<any>{
    let options = {};

    if(filter && !isNull(filter)){
      let params:HttpParams = new HttpParams();
      for (const key in filter) { 
        if (filter.hasOwnProperty(key)) {
          const element = filter[key];
          params = params.set(key, element);
        }
      }  
      options['params'] = params;
    }

    return this.$http.get<any>(ENDPOINT + path, options);
  }
}
