import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { RestApiService } from 'src/app/shared/services/rest-api.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';


@Component({
  selector: 'c:ourFriends',
  templateUrl: './our-friends.component.html',
  styleUrls: ['./our-friends.component.scss']
})
export class OurFriendsComponent implements OnInit {
  @Input() section: any = {};
  @Input() activeLang: string = 'en_US';
  lstFriends: any = [];

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private _ActivateRoute: ActivatedRoute,
    private $restApi: RestApiService) { }

  ngOnInit() {
    this.loadRecord();
  }

  loadRecord() {

    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
			{
				$(document).ready(function () {
					setTimeout(function(){
            var slider = $('#lightSliderFri').lightSlider({
							autoWidth:true,
							item: 3,
							loop: true,
							slideMove: 1,
							currentPagerPosition: 'middle',
							controls: false,
              slideMargin: 0,
              pager: false,
            });
        $('#goToPrevSlide').on('click', function () {
          slider.goToPrevSlide();
          });
        $('#goToNextSlide').on('click', function () {
          slider.goToNextSlide();
        });
          },500);
				});
      }
		`;

    //load our partner from db
    let filter: any = {
      record_type: 'friend'
    };
    this.$restApi.getByPath('/public-people', filter).subscribe(res => {
      let lstAllRecords = res.data;

      let lstLangs = JSON.parse(localStorage.getItem(GlobalVarService.KEY_LANGS));
      let lstSubs = [];

      //setup default language to all lang
      lstAllRecords.forEach(friend => {
        if (friend.langs && friend.langs.en_US) {
          let defualtLang = friend.langs.en_US;

          lstLangs.forEach(lang => {
            if (!friend.langs[lang.lang_code]) {

              let newLang = {};
              newLang[lang.lang_code] = defualtLang;
              console.log("newlang", newLang);
              Object.assign(friend.langs, newLang);
            }
          });
        }

        //setup sub list
        lstSubs.push(friend);
        if (lstSubs.length == 3) {
          this.lstFriends.push(lstSubs);
          // this.lstFriends.push(lstSubs);
          lstSubs = [];
        }
      });

      if (lstSubs.length) {
        this.lstFriends.push(lstSubs);
        lstSubs = [];
      }
      // console.log("this.lstFriends", this.lstFriends);
      this._renderer2.appendChild(this._document.body, script);
    });
  }

}
