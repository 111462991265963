import { Component, OnInit } from '@angular/core';
import { HttpGetJsonService } from "../../../shared/http-service/http-get-json.service";
import { ActivatedRoute } from "@angular/router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import { RestApiService } from 'src/app/shared/services/rest-api.service';



@Component({
	selector: 'app-q-a-pages',
	templateUrl: './q-a-pages.component.html',
	styleUrls: ['./q-a-pages.component.scss']
})
export class QAPagesComponent implements OnInit {
	section: any = {};
	paramsId: string;
	activeLang: any = 'en_US';
	thisPage: any = {}
	lstSections: any = [];
	loadingPage:boolean = true;

	lstCategories:any = [];
	mapCategoryArticles:any = {};
	loadingFAQ:boolean = true;

	constructor(private _HttpGetJsonService: HttpGetJsonService, 
				private activeRoute: ActivatedRoute,
				private listUtil:ListUtilsService,
				private $restApi: RestApiService) { }

	ngOnInit() {
		AOS.init({});

		this.activeRoute.paramMap.subscribe(params => {
			let isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[isocode].lang_code;
		});


		// .........
		this._HttpGetJsonService.getFaqData().subscribe(data => {
			this.section = data['data'][0].all_sections;
			console.log(this.section);
		});

		this.loadPage();
		this.loadFAQ();

	}

	loadPage(){
		this.loadingPage = true; 
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_faq"));
		this.lstSections = this.thisPage.all_sections;

		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.loadingPage = false;
	}

	loadFAQ(){

		this.loadingFAQ = true;
		//load category first 
		this.$restApi.getByPath('/public-categories', {record_type: 'faq'}).subscribe(res => {
			this.lstCategories = res.data;
			console.log(">>>>>>", this.lstCategories);

			let filterArticle = {
				record_type: "faq",
				order_col: 'posted_at',
				order_by: 'desc'
			};
			this.$restApi.getByPath('/public-articles', filterArticle).subscribe(res => {
				let lstArticles = res.data;
				this.listUtil.setupArticleLangs(lstArticles);

				lstArticles.forEach(article => {

					//we will display only the article that has category
					if(article.category_ids){
						let categoryid = article.category_ids;
						let lstCategoryIds = categoryid.indexOf(',') > -1 ? categoryid.split(','): [categoryid];

						lstCategoryIds.forEach(cateId => {
							if(!this.mapCategoryArticles[cateId]){
								this.mapCategoryArticles[cateId] = [];
							}
							this.mapCategoryArticles[cateId].push(article);
						});
					}
				});
				console.log('mapCategoryArticles', this.mapCategoryArticles);
				this.loadingFAQ = false;
			})
		})
	}
}
