import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ActivatedRoute } from "@angular/router";
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import { JsonPipe } from '@angular/common';


@Component({
	selector: 'app-business-pages',
	templateUrl: './business-pages.component.html',
	styleUrls: ['./business-pages.component.scss']
})
export class BusinessPagesComponent implements OnInit {

	paramsId: string;
	activeLang: any;
	section: any = {};
	thisPage: any = {}
	lstSections: any = [];
	loadingPage: boolean = true;
	comInfo:string='';
	constructor(private _ActivatedRoute: ActivatedRoute, 
				private $sitePage: SitePageService,
				private listUtil:ListUtilsService) { }

	ngOnInit() {
		AOS.init({});

		// Language change
		this._ActivatedRoute.paramMap.subscribe(params => {
			let isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[isocode].lang_code;
		});

		this.loadPage();
	}

	loadPage() {
		this.loadingPage = true;
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_business"));
		this.lstSections = this.thisPage.all_sections;

		this.comInfo = JSON.parse(localStorage.getItem(GlobalVarService.KEY_COM_INFO));
		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.loadingPage = false;
	}

}
