import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestApiService } from './shared/services/rest-api.service';
import { GlobalVarService } from './shared/services/global-var.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vipay-website';
  paramId: any;
  public loading = false;
  com_info: any = {};

  param = { value: '@ngrx translation' };

  constructor(private spinner: NgxSpinnerService,
              private $restApi : RestApiService,

              ) { }
  ngOnInit() {
    this.loading = true;

    this.spinner.show();

    //get company info
    this.$restApi.getByPath('/public-company', {}).subscribe(res => {
      //store the info in local storage
      let company_info = res.data;
      let lstAvailableLangs = company_info.available_languages ? company_info.available_languages : [];
      localStorage.setItem(GlobalVarService.KEY_COM_INFO, JSON.stringify(company_info));
      localStorage.setItem(GlobalVarService.KEY_LANGS, JSON.stringify(lstAvailableLangs));

      
      this.com_info = company_info;
    });

    //we will load all theme page info in first load
    this.$restApi.getByPath('/theme-page', {}).subscribe(res => {
      let lstPages:any = res.data;
      lstPages.forEach(page => {

    //store all page info in local storage
        localStorage.setItem(GlobalVarService.KEY_PAGES + "_" + page.name, JSON.stringify(page));
      });
      this.loading = false;
      this.spinner.hide();
    });

  }
}
