import { Component, OnInit, Renderer2, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { RestApiService } from 'src/app/shared/services/rest-api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'c:homeMedia',
  templateUrl: './home-media.component.html',
  styleUrls: ['./home-media.component.scss']
})
export class HomeMediaComponent implements OnInit {
  @Input() section: any = {};
  @Input() activeLang:string = 'en_US';

  latestNews:any = {};
  latestPromos:any = {};
  latestEvent:any = {};
  isocode:string = 'en';
  thisFrm:any = {
    loading: true
  };

  paramsId: string;
  constructor(private _renderer2: Renderer2,
            private $resAPI: RestApiService,
            private activeRoute: ActivatedRoute,
            @Inject(DOCUMENT) private _document) { }



  ngOnInit() {
    this.loadArticle();

    this.activeRoute.paramMap.subscribe(params=>{
      this.isocode = params.has("activeLang") ? params.get("activeLang") : 'en';
    });
  }


  loadArticle(){

    this.thisFrm.loading = true;
    //get the latest news
    let filterNews = {
      record_type: "news",
      order_col: 'posted_at',
      order_by: 'desc',
      limit: 1
    };
    this.$resAPI.getByPath('/public-articles', filterNews).subscribe(res => {
      this.latestNews = res.data[0];

      //get the latest events
      let filterEvents = {
        record_type: "events",
        order_col: 'posted_at',
        order_by: 'desc',
        limit: 1
      };
      this.$resAPI.getByPath('/public-articles', filterEvents).subscribe(res => {
        this.latestEvent = res.data[0];
        console.log(">>>>>event", res);

        //get the latest promos
        let filterPromos = {
          record_type: "promos",
          order_col: 'posted_at',
          order_by: 'desc',
          limit: 1
        };
        this.$resAPI.getByPath('/public-articles', filterPromos).subscribe(res => {
          console.log(">>>>>", res);
          this.latestPromos = res.data[0];
          console.log("lastestPromos", this.latestPromos);

          this.thisFrm.loading = false;
          this.loadSlider();
        });

      });
    });





  }

  loadSlider(){
    // Slide card
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
        {
            $(document).ready(function () {
                setTimeout(function(){
                     $('#lightSlider').lightSlider({
                        adaptiveHeight: false,
                        item: 3,
                        slideMargin: 30,
                        loop: false,
                        slideMove: 1,
                        controls:false,
                        responsive : [
                          {
                              breakpoint:1000,
                              settings: {
                                  item:3,
                                  slideMove:1,
                                }
                          },
                          {
                              breakpoint:950,
                              settings: {
                                  item:2,
                                  slideMove:1
                                }
                          },
                          {
                            breakpoint:650,
                            settings: {
                                item:1,
                                slideMove:1
                              }
                        }
                      ]
                      });
                },0);
            });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }

}
