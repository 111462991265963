import { Injectable } from '@angular/core';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class ListUtilsService {

	constructor(){}

	cloneSectionLang(lstSections){
		let lstAvailableLangs = JSON.parse(localStorage.getItem(GlobalVarService.KEY_LANGS));
		if(lstAvailableLangs && lstAvailableLangs.length){
			lstSections.forEach(section => {
			
				if(section.langs && section.langs.en_US){
					let defaultLang = section.langs.en_US;
					lstAvailableLangs.forEach(lang => {
						if(!section.langs[lang.lang_code]){
							let newLang = {};
							newLang[lang.lang_code] = defaultLang;
							Object.assign(section.langs, newLang);
						}
					});
				}
				
				//clone language translate for asset
				if(section.assets && section.assets.length){
					section.assets.forEach(asset => {
						if(asset.langs && asset.langs.en_US){
							let defaultAssetLang = asset.langs.en_US;
							lstAvailableLangs.forEach(lang => {
								if(!asset.langs[lang.lang_code]){
									let newLang = {};
									newLang[lang.lang_code] = defaultAssetLang;
									Object.assign(asset.langs, newLang);
								}
							});
						}
					});
				}
	
				//check if there are subsection and clone the language
				if(section.sub_sections && section.sub_sections.length){
					this.cloneSectionLang(section.sub_sections);
				}
			});
		}
	}

	setupArticleLangs(lstRecords){
		let lstAvailableLangs = JSON.parse(localStorage.getItem(GlobalVarService.KEY_LANGS));
		if(lstAvailableLangs && lstAvailableLangs.length){
			lstRecords.forEach(record => {
				if(record.langs && record.langs.en_US){
					let defaultLang = record.langs.en_US;
					lstAvailableLangs.forEach(lang => {
						if(!record.langs[lang.lang_code]){
							let newLang = {};
							newLang[lang.lang_code] = defaultLang;
							Object.assign(record.langs, newLang);
						}
					});
				}

				if(record.sub_posts && record.sub_posts.length){
					this.setupArticleLangs(record.sub_posts);
				}
			});
		}
	}

}
