import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PersonalPageService } from '../../shared/personal-page/personal-page.service'
import { ActivatedRoute } from "@angular/router";
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'app-personal-pages',
	templateUrl: './personal-pages.component.html',
	styleUrls: ['./personal-pages.component.scss']
})
export class PersonalPagesComponent implements OnInit {

	activeLang: any;
	thisPage: any = {}
	lstSections: any = [];
	loadingPage: boolean = true;

	constructor(private activeRoute: ActivatedRoute, 
				private $sitePage: SitePageService,
				private listUtil: ListUtilsService,
			
				) {
				
				 }

				

	ngOnInit() {
		AOS.init({});

		let filter: any = {
			name: 'personal'
		};

		// Language change
		this.activeRoute.paramMap.subscribe(params => {
			let isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[isocode].lang_code;
		});


		this.loadPage(filter);
	}

	loadPage(filter){
		this.loadingPage = true;
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_personal"));
		this.lstSections = this.thisPage.all_sections;

		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.loadingPage = false; 
	}

}
