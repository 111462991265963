import { Component, OnInit, Renderer2, Inject, Input, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { RestApiService } from 'src/app/shared/services/rest-api.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';

@Component({
	selector: 'c:ourPartner',
	templateUrl: './our-partner.component.html',
	styleUrls: ['./our-partner.component.scss']
})
export class OurPartnerComponent implements OnInit, AfterViewInit {
	@Input() section: any = {};
	@Input() activeLang:string = 'en_US';

	paramsId: string;
	lstPartners: any = [];

	constructor(private _renderer2: Renderer2,
		private $restApi: RestApiService,
		@Inject(DOCUMENT) private _document,
		private _ActivateRoute: ActivatedRoute) { }


	ngOnInit() {

		let script = this._renderer2.createElement('script');
			script.type = `text/javascript`;
			script.text = `
				{
					$(document).ready(function () {
						setTimeout(function(){
					$('#lightSliderPar').lightSlider({
						adaptiveHeight: false,
						item: 4,
						slideMargin: 30,
						loop: false,
						slideMove: 1,
						controls: false,
						responsive: [
							{
								breakpoint: 900,
								settings: {
									item: 3,
									slideMove: 1,
									slideMargin: 6,
								}
							},
							{
								breakpoint: 580,
								settings: {
									item: 2,
									slideMove: 1
								}
							},
							{
								breakpoint: 380,
								settings: {
									item: 1,
									slideMove: 1
								}
							}
						]
					});
						},0);
					});
				}
			`;

		//load our partner from db
		let filter: any = {
			record_type: 'partner'
		};
		this.$restApi.getByPath('/public-people', filter).subscribe(res => {
			this.lstPartners = res.data;

			let lstLangs = JSON.parse(localStorage.getItem(GlobalVarService.KEY_LANGS));
			//setup default language to all lang
			this.lstPartners.forEach(partner => {
				let defualtLang = partner.langs.en_US;

				lstLangs.forEach(lang => {
					if (!partner.langs[lang.lang_code]) {

						let newLang = {};
						newLang[lang.lang_code] = defualtLang;
						// console.log("newlang", newLang);
						Object.assign(partner.langs, newLang);
					}
				});
			});


			this._renderer2.appendChild(this._document.body, script);
		});
	}

	ngAfterViewInit() {
		// setTimeout(() => {
		// 	$('#lightSliderPar').lightSlider({
		// 		adaptiveHeight: false,
		// 		item: 4,
		// 		slideMargin: 0,
		// 		loop: false,
		// 		slideMove: 1,
		// 		currentPagerPosition: 'middle',
		// 		responsive: [
		// 			{
		// 				breakpoint: 900,
		// 				settings: {
		// 					item: 3,
		// 					slideMove: 1,
		// 					slideMargin: 6,
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 580,
		// 				settings: {
		// 					item: 2,
		// 					slideMove: 1
		// 				}
		// 			},
		// 			{
		// 				breakpoint: 380,
		// 				settings: {
		// 					item: 1,
		// 					slideMove: 1
		// 				}
		// 			}
		// 		]
		// 	});
		// }, 0);
	}

}
