import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestApiService } from '../../shared/services/rest-api.service';
import { GlobalVarService } from '../../shared/services/global-var.service';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  com_info: any = {};

  param = { value: '@ngrx translation' };
  translate: TranslateService;

  thisFrm: any = {
    activeLang: 'en_US',
    activePage: "home"
  };
  mapLangs: any = {
    'km_KH': {
      name: 'ខ្មែរ',
      image_path: "assets/img/flags/kh.png",
      iso_code: 'km'
    },
    'en_US': {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      iso_code: 'en'
    },
    "zh_CN": {
      name: "China",
      image_path: "assets/img/flags/ch.png",
      iso_code: 'zh'
    },
    'km': {
      lang: 'Khmer',
      image_path: "assets/img/flags/kh.png",
      lang_code: "km_KH"
    },
    'en': {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      lang_code: "en_US"
    },
    "zh": {
      name: "China",
      image_path: "assets/img/flags/ch.png",
      lang_code: "zh_CN"
    },
  };

  constructor(
    private $restApi: RestApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    translateService: TranslateService) {
    this.translate = translateService;
    this.translate.setDefaultLang('en');
  }


  ChangeLang(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      let activeLang: string = params.get('activeLang');
      this.ChangeLang(activeLang);

      if (activeLang) {
        this.thisFrm.activeLang = this.mapLangs[activeLang].lang_code;
      }

      let url: Observable<string> = this.activatedRoute.url.pipe(map(segments => segments.join('')));
      // console.log('Url footer', url)
    });

    this.activatedRoute.url.subscribe(segments => {
      // console.log('segment footer', segments);
    })

    //get company info
    this.$restApi.getByPath('/public-company', {}).subscribe(res => {
      //store the info in local storage
      let company_info = res.data;
      let lstAvailableLangs = company_info.available_languages ? company_info.available_languages : [];
      localStorage.setItem(GlobalVarService.KEY_COM_INFO, JSON.stringify(company_info));
      localStorage.setItem(GlobalVarService.KEY_LANGS, JSON.stringify(lstAvailableLangs));
      this.com_info = company_info;
    });

    //we will load all theme page info in first load
    this.$restApi.getByPath('/theme-page', {}).subscribe(res => {
      let lstPages: any = res.data;
      lstPages.forEach(page => {
        //store all page info in local storage
        localStorage.setItem(GlobalVarService.KEY_PAGES + "_" + page.name, JSON.stringify(page));
      });

    });

  }

  routeChange(lang_code: string, pageName: string) {
    this.router.navigate([this.mapLangs[lang_code].iso_code, pageName]);
  }

}
