import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ActivatedRoute } from "@angular/router";
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import {Router} from  '@angular/router'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';



@Component({
	selector: 'app-about-us-pages',
	templateUrl: './about-us-pages.component.html',
	styleUrls: ['./about-us-pages.component.scss']
})
export class AboutUsPagesComponent implements OnInit {
 
	activeLang: any;
	thisPage: any = {}
	lstSections: any = [];
	loadingPage:boolean = true;
	careertxt: string = "Career";

	param = { value: '@ngrx translation' };
	translate: TranslateService;

	thisFrm:any={
		activeLang: 'en_US',
		activePage: "home"
	  };

	constructor(private activeRoute: ActivatedRoute, 
				private $sitePage: SitePageService,
				private listUtil:ListUtilsService,
				private router:Router,
				translateService: TranslateService
				) {
					this.translate = translateService;
					this.translate.setDefaultLang('en');
				 }


				mapLangs:any={
					'km_KH' : {
					  name: 'ខ្មែរ',
					  image_path: "assets/img/flags/kh.png",
					  iso_code: 'km'
					},
					'en_US' : {
					  name: 'English',
					  image_path: "assets/img/flags/uk.png",
					  iso_code: 'en'
					},
					"zh_CN" : {
					  name: "China",
					  image_path: "assets/img/flags/ch.png",
					  iso_code: 'zh'
					},
					'km' : {
					  lang: 'Khmer',
					  image_path: "assets/img/flags/kh.png",
					  lang_code: "km_KH"
					},
					'en' : {
					  name: 'English',
					  image_path: "assets/img/flags/uk.png",
					  lang_code: "en_US"
					},
					"zh" : {
					  name: "China",
					  image_path: "assets/img/flags/ch.png",
					  lang_code: "zh_CN"
					},
				  };
			

				  ChangeLang(language: string) {
					this.translate.use(language);
					}
	ngOnInit() {
		AOS.init({});
		this.activeRoute.paramMap.subscribe(params => {
			let activeLang: string = params.get('activeLang');
			this.ChangeLang(activeLang);
			if(activeLang){
			  this.thisFrm.activeLang = this.mapLangs[activeLang].lang_code;
		 
			}
		
		  });



		// Language change
		this.activeRoute.paramMap.subscribe(params => {
			let isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[isocode].lang_code;
		});

		this.loadPage();
		
	}

	loadPage() {
		this.loadingPage = true;

		this.loadingPage = true;
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_about"));
		this.lstSections = this.thisPage.all_sections;

		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.loadingPage = false;
		 
	}
	onCareer(lang_code:string, pageName: string){
		this.router.navigate([this.mapLangs[lang_code].iso_code, pageName]); 
	}

}
