import { Component, OnInit, Input } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ActivatedRoute } from '@angular/router';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';

@Component({
	selector: 'app-home-pages',
	templateUrl: './home-pages.component.html',
	styleUrls: ['./home-pages.component.scss']
})
export class HomePagesComponent implements OnInit {
	homeData: any = [];
	paramsId: string;

	thisPage: any = {}
	lstSections: any = [];
	thisPageStatus:any = {
		loading : true,
		lstAvailableLangs: [],
		activeLang: 'en_US'
	};

	constructor(private $sitePage: SitePageService,
		private activeRoute: ActivatedRoute,
		private listUtil: ListUtilsService) { }

	ngOnInit() {

		// Animation
		AOS.init({});

		//get home page data from DB
		let filter: any = {
			name: 'home'
		};

		this.thisPageStatus.lstAvailableLangs = JSON.parse(localStorage.getItem(GlobalVarService.KEY_LANGS));
		

		this.activeRoute.paramMap.subscribe(params => {
			let activeLang = params.get("activeLang");
			this.thisPageStatus.activeLang = GlobalVarService.mapLangs[activeLang].lang_code;
		});

		this.loadPage(filter);
	}

	loadPage(filter) {
		this.thisPageStatus.loading = true;
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_home"));
		this.lstSections = this.thisPage.all_sections;

		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.thisPageStatus.loading = false; 
	}

}
