import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpGetJsonService {

  private _careerUrl = 'assets/json/careers-page.json';
  private _faqUrl = "assets/json/faq-page.json";
  private _newsUrl = 'assets/json/news.json';
  constructor(private _HttpClient: HttpClient) { }

  getCareerData() {
    return this._HttpClient.get(this._careerUrl);
  }

  getFaqData() {
    return this._HttpClient.get(this._faqUrl);
  }

  getNewsData() {
    return this._HttpClient.get(this._newsUrl);
  }
}
