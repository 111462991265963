import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-promos-pages',
  templateUrl: './promos-pages.component.html',
  styleUrls: ['./promos-pages.component.scss']
})
export class PromosPagesComponent implements OnInit {

  // Promotion image
  slides = [
    { img: 'assets/img/promos/pro_01.jpg' },
    { img: 'assets/img/promos/pro_02.jpg' },
    { img: 'assets/img/promos/pro_03.jpg' },
    { img: 'assets/img/promos/pro_04.jpg' },
    { img: 'assets/img/promos/pro_05.jpg' },
    { img: 'assets/img/promos/pro_06.jpg' },
    { img: 'assets/img/promos/pro_07.jpg' },
    { img: 'assets/img/promos/pro_08.jpg' },
    { img: 'assets/img/promos/pro_09.png' },
    { img: 'assets/img/promos/pro_10.jpg' },
    { img: 'assets/img/promos/pro_11.jpg' },
    { img: 'assets/img/promos/pro_12.jpg' },
    { img: 'assets/img/promos/pro_13.jpg' },
    { img: 'assets/img/promos/pro_14.jpg' },
    { img: 'assets/img/promos/pro_15.jpg' },
    { img: 'assets/img/promos/pro_16.jpg' },
    { img: 'assets/img/promos/pro_17.jpg' },
    { img: 'assets/img/promos/pro_18.jpg' },
    { img: 'assets/img/promos/pro_19.jpg' },
    { img: 'assets/img/promos/pro_20.jpg' },
    { img: 'assets/img/promos/pro_21.jpg' },
    { img: 'assets/img/promos/pro_22.jpg' },
    { img: 'assets/img/promos/pro_23.jpg' },
    { img: 'assets/img/promos/pro_24.jpg' },
    { img: 'assets/img/promos/pro_25.jpg' },
    { img: 'assets/img/promos/pro_26.jpg' },
    { img: 'assets/img/promos/pro_27.png' },
    { img: 'assets/img/promos/pro_28.png' },
    { img: 'assets/img/promos/pro_29.jpg' },
    { img: 'assets/img/promos/pro_30.jpg' },
    { img: 'assets/img/promos/pro_31.jpg' },
    { img: 'assets/img/promos/pro_32.jpg' },
    { img: 'assets/img/promos/pro_33.jpg' },
    { img: 'assets/img/promos/pro_34.jpg' },
    { img: 'assets/img/promos/pro_35.jpg' },
    { img: 'assets/img/promos/pro_36.jpg' },
    { img: 'assets/img/promos/pro_37.png' },
    { img: 'assets/img/promos/pro_38.jpg' },
    { img: 'assets/img/promos/pro_39.jpg' },
    { img: 'assets/img/promos/pro_40.jpg' },
    { img: 'assets/img/promos/pro_41.jpg' },
    { img: 'assets/img/promos/pro_42.jpg' },
    { img: 'assets/img/promos/pro_43.jpg' },
    { img: 'assets/img/promos/pro_44.jpg' },
    { img: 'assets/img/promos/pro_45.jpg' },
    { img: 'assets/img/promos/pro_46.jpg' },
    { img: 'assets/img/promos/pro_47.jpg' },
    { img: 'assets/img/promos/pro_48.jpg' },
    { img: 'assets/img/promos/pro_49.jpg' },
    { img: 'assets/img/promos/pro_50.jpg' },
    { img: 'assets/img/promos/pro_51.jpg' },
    { img: 'assets/img/promos/pro_52.jpg' },
    { img: 'assets/img/promos/pro_53.jpg' },
    { img: 'assets/img/promos/pro_54.jpg' },
    { img: 'assets/img/promos/pro_55.jpg' },
    { img: 'assets/img/promos/pro_56.jpg' },
    { img: 'assets/img/promos/pro_57.png' },
    { img: 'assets/img/promos/pro_58.jpg' },
    { img: 'assets/img/promos/pro_59.jpg' },
    { img: 'assets/img/promos/pro_60.jpg' },
    { img: 'assets/img/promos/pro_61.png' },
  ];

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document) { }

  ngOnInit() {

    // Animation
    AOS.init({});

    // Slide card
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
        {
            $(document).ready(function () {
                setTimeout(function(){
                     $('#lightSliderPromo').lightSlider({
                        adaptiveHeight: true,
                        gallery:true,
                        item:1,
                        vertical:true,
                        vThumbWidth:80,
                        thumbItem:6,
                        slideMargin:10,
                        controls:false,
                        keyPress: true,
                      });
                },0);
            });

            $(document).ready(function () {
              setTimeout(function(){
                   $('#lightSliderPromoMobile').lightSlider({
                      adaptiveHeight: true,
                      gallery:true,
                      item:1,
                      thumbItem:5,
                      slideMargin:10,
                      controls:false,
                      keyPress: true,
                    });
              },0);
          });
        }
    `;
    this._renderer2.appendChild(this._document.body, script);
  }

}
