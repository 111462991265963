import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'c:infoIconRight001',
  templateUrl: './info-icon-right001.component.html',
  styleUrls: ['./info-icon-right001.component.scss']
})
export class InfoIconRight001Component implements OnInit {
  @Input() article;
  @Input() isLast:boolean = false;
  @Input() activeLang: string = "en_US";
  @Input() icon: any;
  thisPage:any ={}
  lstSections:any = [];
  constructor() { }

  ngOnInit() {
  }

}
