import { Component, OnInit, Input } from '@angular/core';
import { HomePageService } from '../../shared/home-page.service';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'c:homeHeader',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  // public homeData = [];
  @Input() section: any = {};
  @Input() activeLang:string = 'en_US';
 
  constructor(private homePageService: HomePageService, 
              private _ActivateRoute: ActivatedRoute, 
              ) { }

  ngOnInit() { 
  }
}
