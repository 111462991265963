import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePagesComponent } from './pages/home-pages/home-pages.component';
import { PersonalPagesComponent } from './pages/personal-pages/personal-pages.component';
import { BusinessPagesComponent } from './pages/business-pages/business-pages.component';
import { AboutUsPagesComponent } from './pages/about-us-pages/about-us-pages.component';
import { NewsPagesComponent } from './pages/sub-pages/news-pages/news-pages.component';
import { PromosPagesComponent } from './pages/sub-pages/promos-pages/promos-pages.component';
import { CareerPagesComponent } from './pages/sub-pages/career-pages/career-pages.component';
import { QAPagesComponent } from './pages/sub-pages/q-a-pages/q-a-pages.component';
import { BusinessCenterComponent } from './pages/sub-pages/business-center/business-center.component';

const routes: Routes = [
  {path: '', redirectTo: 'en/home', pathMatch: 'full'},
  {path: ':activeLang/home', component: HomePagesComponent},
  {path: ':activeLang/personal', component: PersonalPagesComponent},
  {path: ':activeLang/business', component: BusinessPagesComponent},
  {path: ':activeLang/about-us', component: AboutUsPagesComponent},
  {path: ':activeLang/news', component: NewsPagesComponent, data: {record_type: 'news'}},
  {path: ':activeLang/news/:slug', component: NewsPagesComponent, data: {record_type: 'news'}},
  {path: ':activeLang/promos', component: PromosPagesComponent},
  {path: ':activeLang/event', component: NewsPagesComponent, data: {record_type: 'events'}},
  {path: ':activeLang/events', component: NewsPagesComponent, data: {record_type: 'events'}},
  {path: ':activeLang/events/:slug', component: NewsPagesComponent, data: {record_type: 'events'}},
  {path: ':activeLang/career', component: CareerPagesComponent},
  {path: ':activeLang/faq', component: QAPagesComponent},
  {path: ':activeLang/business-center', component: BusinessCenterComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
