import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HttpGetJsonService } from '../../../shared/http-service/http-get-json.service';
import { ActivatedRoute } from "@angular/router";
import { SitePageService } from 'src/app/shared/services/_object/site-page.service';
import { GlobalVarService } from 'src/app/shared/services/global-var.service';
import { ListUtilsService } from 'src/app/shared/services/list-utils.service';
import { RestApiService } from 'src/app/shared/services/rest-api.service';


@Component({
	selector: 'app-career-pages',
	templateUrl: './career-pages.component.html',
	styleUrls: ['./career-pages.component.scss']
})
export class CareerPagesComponent implements OnInit { 
	activeLang: any = 'en_US';
	thisPage: any = {}
	lstSections: any = [];
	loadingPage:boolean = true;

	lstCareers:any = [];
	loadingCareer:boolean = true;

	constructor(private _HttpGetJsonService: HttpGetJsonService, 
				private activeRoute: ActivatedRoute, 
				private $sitePage: SitePageService,
				private listUtil:ListUtilsService,
				private $restApi: RestApiService) { }

	ngOnInit() {
		AOS.init({});

		// Language change
		this.activeRoute.paramMap.subscribe(params => {
			let isocode = params.get("activeLang");
			this.activeLang = GlobalVarService.mapLangs[isocode].lang_code;
		}); 
		
		this.loadPage();
		this.loadActiveCareer();
	}

	loadPage(){
		this.loadingPage = true;
		this.thisPage = JSON.parse(localStorage.getItem(GlobalVarService.KEY_PAGES + "_career"));
		this.lstSections = this.thisPage.all_sections;

		//check if there are missed language,
		//if there are missed language, we will clone from en_US
		this.listUtil.cloneSectionLang(this.lstSections);
		this.loadingPage = false;
	}

	loadActiveCareer() {
		this.loadingCareer = true;
		this.$restApi.getByPath('/public-articles', {"record_type": 'career'}).subscribe(res => { 
			this.lstCareers = res.data;
			this.loadingCareer = false;
		});
	}
}
