import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePagesComponent } from './pages/home-pages/home-pages.component';
import { PersonalPagesComponent } from './pages/personal-pages/personal-pages.component';
import { BusinessPagesComponent } from './pages/business-pages/business-pages.component';
import { AboutUsPagesComponent } from './pages/about-us-pages/about-us-pages.component';
import { QAPagesComponent } from './pages/sub-pages/q-a-pages/q-a-pages.component';
import { NewsPagesComponent } from './pages/sub-pages/news-pages/news-pages.component';
import { PromosPagesComponent } from './pages/sub-pages/promos-pages/promos-pages.component';
import { CareerPagesComponent } from './pages/sub-pages/career-pages/career-pages.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { HomeHeaderComponent } from './_components/home-header/home-header.component';
import { HomeMediaComponent } from './_components/home-media/home-media.component';
import { OurPartnerComponent } from './_components/our-partner/our-partner.component';
import { OurFriendsComponent } from './_components/our-friends/our-friends.component';
import { InfoIconRight001Component } from './_components/info-icon-right001/info-icon-right001.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {FormsModule} from '@angular/forms';
import { FooterComponent } from './_components/footer/footer.component';
import { BusinessCenterComponent } from './pages/sub-pages/business-center/business-center.component';




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    HomePagesComponent,
    PersonalPagesComponent,
    BusinessPagesComponent,
    AboutUsPagesComponent,
    QAPagesComponent,
    NewsPagesComponent,
    PromosPagesComponent,
    CareerPagesComponent,
    NavbarComponent,
    HomeHeaderComponent,
    HomeMediaComponent,
    OurPartnerComponent,
    OurFriendsComponent,
    InfoIconRight001Component,
    FooterComponent,
    BusinessCenterComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TooltipModule,
    HttpClientModule,
    NgxSpinnerModule,
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    NgxLoadingModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PopoverModule.forRoot()

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
