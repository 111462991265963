import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVarService { 
  static companyName = 'VIPAY';

  static KEY_COM_INFO = 'site_com_info_' + GlobalVarService.companyName;
  static KEY_LANGS = 'site_available_lang_' + GlobalVarService.companyName;
  static KEY_PAGES = 'site_all_page_info_' + GlobalVarService.companyName;

  static mapLangs:any={
    'km_KH' : {
      name: 'Khmer',
      image_path: "assets/img/flags/kh.png",
      iso_code: 'km'
    },
    'en_US' : {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      iso_code: 'en'
    },
    "zh_CN" : {
      name: "China",
      image_path: "assets/img/flags/ch.png",
      iso_code: 'zh'
    },
    'km' : {
      lang: 'Khmer',
      image_path: "assets/img/flags/kh.png",
      lang_code: "km_KH"
    },
    'en' : {
      name: 'English',
      image_path: "assets/img/flags/uk.png",
      lang_code: "en_US"
    },
    "zh" : {
      name: "China",
      image_path: "assets/img/flags/ch.png",
      lang_code: "zh_CN"
    },
  };
  constructor() {  
  }
}
